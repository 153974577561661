import React, { useEffect, useRef } from "react";
import fireDb from "../firebase";
import markerIcon from '../circle-practice.png';
/* global google */


const Map = () => {
  const count = useRef(null);

  useEffect(() => {
    let map;
    let icon = markerIcon;
    let infoWindow;

    if (count.current == null) {
       fireDb.child("messages").orderByChild("status").equalTo("Approved").on("value", (snapshot) => {
        if (snapshot.val() !== null) {
          initMap();
          snapshot.forEach((data) => {
            let content = `<h6>${data.val().studentname}</h6><p>${data.val().cohort}</p>`;
            addToMap(data.val().latitude, data.val().longitude, content);
          });
        }
      });
    }

    // Add zoom
    // Remove controls
    // Focus map on Europe
    // No additional styles
    const initMap = () => {
      var options = {
        zoom: 5,
        mapTypeControl: false,
        fullscreenControl: false,
        center:{lat:50.925753, lng:2.425258},
        styles: []
      }
      map = new google.maps.Map(document.getElementById('map'), options);    
    }

    const addToMap = (latitude, longitude, content) => {
      let marker = new google.maps.Marker ({
        position:{lat: parseFloat(latitude), lng: parseFloat(longitude)}, 
        map: map,
        icon: icon
      });

      marker.addListener("click", () => {
        if (infoWindow) infoWindow.close();
        infoWindow = new google.maps.InfoWindow({
          content: content,
        });
        infoWindow.open(marker.get("map"), marker);
      })
    }

    return () => { count.current = 1; }
  }, []);
  
  return (
    <div id="map"></div>
  );
};

export default Map;
